import {
  type MetaFunction,
  type LoaderFunctionArgs,
  redirect,
  json,
  ActionFunctionArgs
} from '@remix-run/node'

import { Workspace } from '~/database/static-types'
import {
  createServiceSupabase,
  createUserSupabase,
  getAuthUser,
  getWorkspaces
} from '~/lib/supabase'

import { Anchor } from '~/components/ui/link'
import Navbar from '~/components/navbar'
import Footer from '~/components/footer'
import { Content } from '~/components/ui/page-content'
import { WebHeader } from '~/components/ui/page-header'
import { email, waitlistForm } from '~/validation'
import SubmitButton from '~/components/SubmitButton'
import { useEffect, useRef } from 'react'
import posthog from 'posthog-js'
import { usePosthogLoaded } from '~/providers/posthog-provider'
import TextInput from '~/components/ui/text-input'
import ValidatedForm from '~/components/ui/validated-form'

export async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const { supabase, headers } = createUserSupabase(request)
  const user = await getAuthUser(supabase)

  const workspaces = (await getWorkspaces(user, supabase)) as Array<Workspace>
  const defaultWorkspace = workspaces.length > 0 ? workspaces[0] : undefined

  if (user && defaultWorkspace && !searchParams.get('r')) {
    return redirect(`/workspace/${defaultWorkspace.uid}`)
  }

  return json({ user }, { headers })
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()

  const email = formData.get('email') as string
  const phDistinctId = formData.get('ph_distinct_id') as string
  const { supabase: serviceSupabase } = createServiceSupabase()

  const { error } = await serviceSupabase.from('waitlist').insert({
    email,
    invited: false,
    ph_distinct_id: phDistinctId
  })

  if (error) {
    console.error(`Waitlist error`, error.message)
    return json({ errorMessage: error.message, success: false })
  }

  return json({
    success: true
  })
}

export const meta: MetaFunction = () => {
  return [
    { title: 'Infinity - Card sorting' },
    { name: 'description', content: 'Accelerate your user research with card sorting to quickly learn about group preferences and behaviors' }
  ]
}

const App = () => {
  const posthogLoaded = usePosthogLoaded()
  const distinctId = useRef<string>()

  useEffect(() => {
    if (posthogLoaded && !distinctId.current) {
      const id = posthog.get_distinct_id()
      distinctId.current = id
    }
  }, [posthogLoaded])

  return (
    <>
      <Navbar>
        <div>
          <Anchor
            href='/login'
            $size='primary'
            className='text-base flex h-12 cursor-pointer items-center justify-center px-4 text-text-secondary !no-underline ring-inset ring-border-interactive hover:bg-background-hover hover:text-text-primary focus:ring-3 focus:ring-border-interactive focus-visible:ring-3 focus-visible:ring-border-interactive active:bg-background-active'
          >
            Log in
          </Anchor>
        </div>
      </Navbar>
      <Content>
        <WebHeader
          centered
          title={<>Get insights on how people organize content</>}
          description={
            <>
              Accelerate your user research with card sorts to
              quickly learn about group preferences and behaviors
            </>
          }
          actions={
            <ValidatedForm
              formId='waitlist-form'
              validator={waitlistForm}
              fullWidth
              className='flex w-full items-start gap-4'
              successTitle='Requested access'
              successMessage='You have been added to the Infinity waitlist'
            >
              {({ register, getError }) => (
                <>
                  <input
                    name='ph_distinct_id'
                    type='hidden'
                    defaultValue={distinctId.current}
                  />
                  <TextInput
                    label='Email'
                    name='email'
                    defaultValue=''
                    placeholder={'name@mail.com'}
                    layer={1}
                    errorMessage={getError('email')}
                    register={register}
                    hideLabel
                  />
                  <SubmitButton
                    hug
                    label='Request early access'
                    submittingLabel='Request early access'
                    disableOnSuccess
                  />
                </>
              )}
            </ValidatedForm>
          }
        />
        <div className="flex flex-col pt-24 px-4">
          <img width="1440" src="images/Sankeytab.png" className="mx-auto h-auto border-2 border-border-subtle-02" />
        </div>
      </Content>
      <Footer />
    </>
  )
}

export default App

/*
  <>
    <Button
      as='a'
      href='/signup'
      $size='medium'
      $style='primary'
      fullWidth
    >
      Try for free
    </Button>
    <Button
      as='a'
      href='/login'
      $size='medium'
      $style='secondary'
      fullWidth
    >
      Log in
    </Button>
  </>
 */
